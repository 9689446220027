<template>
  <div>
    <pm-Card>
      <template v-slot:title> Informações básicas</template>
      <template v-slot:subtitle> Insira os dados abaixo </template>
      <template v-slot:content>
        <div class="p-fluid formgrid grid" v-if="cad == 1">
          <div class="field col-12 md:col-4">
            <label>Escola de Destino</label>
            <select
              class="p-inputtext p-component"
              v-model="info.escola_id"
              style="appearance: revert !important; width: 100% !important"
            >
              <option value="" disabled selected>
                -- Escolha uma Escola --
              </option>
              <option v-for="n in usuario_logado.escolas" :key="n" :value="n.id">
                {{ n.nome }}
              </option>
            </select>
          </div>
        </div>

        <div class="p-fluid formgrid grid" v-if="info.escola_id != 0">
          <!--<div class="col-md-12"><h5>Sobre o aluno:</h5></div>-->
          <div class="field col-12 md:col-1">
            <label for="firstname">CPF: <b style="color: #f00;">*</b></label>
            <pm-InputMask
              :style="greenColor"
              @change="validateForm"
              mask="999.999.999-99"
              v-on:blur="verificarCPF"
              v-model="info.cpf"
              placeholder="999.999.999-99"
              :class="{ 'p-invalid': validationErrors.cpf && submitted }"
            />
            <small v-show="validationErrors.cpf && submitted" class="p-error">O CPF é obrigatório!</small>
            <small v-show="cpfInvalidoJaExiste" class="p-error"
            >O CPF digitado já está em uso!</small
            >
            <small v-show="cpfInvalidoIncorreto" class="p-error"
            >O CPF digitado não é válido!</small
            >
          </div>
          <div class="field col-12 md:col-2">
            <label for="firstname">Primeiro nome: <b style="color: #f00;">*</b></label>
            <pm-InputText
              id="firstname"
              @change="validateForm"
              @blur="mudarPrimeiraPalavra(info.nome, 1)"
              v-model="info.nome"
              v-on:keypress="isLetter($event)"
              :class="{ 'p-invalid': validationErrors.nome && submitted }"
            />
            <small v-show="validationErrors.nome && submitted" class="p-error"
            >O nome é obrigatório!</small
            >
          </div>
          <div class="field col-12 md:col-3">
            <label for="firstname">Sobrenome do aluno: <b style="color: #f00;">*</b></label>
            <pm-InputText
              id="firstname"
              @change="validateForm"
              @blur="mudarPrimeiraPalavra(info.sobrenome, 2)"
              v-on:keypress="isLetter($event)"
              v-model="info.sobrenome"
              :class="{ 'p-invalid': validationErrors.sobrenome && submitted }"
            />
            <small
              v-show="validationErrors.sobrenome && submitted"
              class="p-error"
            >O sobrenome é obrigatório!</small
            >
          </div>
          <div class="field col-12 md:col-2">
            <label for="nascimento">Nascimento:</label>
            <pm-InputText
              v-model="info.dataNascimento"
              type="date"
              :class="{
                'p-invalid': validationErrors.dataNascimento && submitted,
              }"
            />
            <small
              v-show="validationErrors.dataNascimento && submitted"
              class="p-error"
            >A data de nascimento é obrigatório!</small
            >
          </div>

          <div class="field col-12 md:col-3">
            <label for="lastname">Responsável pelo Aluno:</label>
            <pm-InputText
              id="lastname"
              @blur="mudarPrimeiraPalavra(info.responsavel, 3)"
              v-on:keypress="isLetter($event)"
              v-model="info.responsavel"
            />
          </div>

          <div class="field col-12 md:col-2">
            <label for="lastname">Nome da mãe: <b style="color: #f00;">*</b></label>
            <pm-InputText
              id="lastname"
              @change="validateForm"
              v-model="info.nomeMae"
              @blur="mudarPrimeiraPalavra(info.nomeMae, 4)"
              v-on:keypress="isLetter($event)"
              :class="{ 'p-invalid': validationErrors.nomeMae && submitted }"
            />
            <small
              v-show="validationErrors.nomeMae && submitted"
              class="p-error"
            >O nome da mãe é obrigatório!</small
            >
          </div>
          <div class="field col-12 md:col-2">
            <label for="lastname">Nome do pai:</label>
            <pm-InputText
              id="lastname"
              @blur="mudarPrimeiraPalavra(info.nomePai, 5)"
              v-on:keypress="isLetter($event)"
              v-model="info.nomePai"
            />
          </div>

          <div class="row field col-12 md:col-3">
            <div class="field-radiobutton" style="margin-top: 22px">
              <label>Sexo:</label>&nbsp;&nbsp;
              <pm-RadioButton name="masc" value="M" v-model="info.sexo" />
              <label>Masculino</label>
              <pm-RadioButton
                name="femi"
                value="F"
                v-model="info.sexo"
                style="margin-left: 7px"
              />
              <label>Feminino</label>
              <pm-RadioButton
                name="outros"
                value="O"
                v-model="info.sexo"
                style="margin-left: 7px"
              />
              <label>Outros</label>
            </div>
          </div>
          <div class="row field col-12 md:col-4">
            <div class="field-radiobutton" style="margin-top: 22px">
              <label>Autorização para uso de imagem?</label> &nbsp;&nbsp;
              <pm-RadioButton name="sim" value="1" v-model="info.usoDaImage" />
              <label>Sim</label>
              <pm-RadioButton
                name="nao"
                value="0"
                v-model="info.usoDaImage"
                style="margin-left: 7px"
              />
              <label>Não</label>
            </div>
          </div>

          <div class="field col-12 md:col-12"></div>

          <div class="field col-12 md:col-2">
            <label for="lastname">Nacionalidade:</label>
            <pm-InputText
              id="lastname"
              @blur="mudarPrimeiraPalavra(info.nacionalidade, 6)"
              v-model="info.nacionalidade"
            />
          </div>
          <div class="field col-12 md:col-2">
            <label for="lastname">Naturalidade:</label>
            <pm-InputText
              id="lastname"
              @blur="mudarPrimeiraPalavra(info.naturalidade, 7)"
              v-model="info.naturalidade"
            />
          </div>
          <div class="field col-12 md:col-2">
            <label for="sangue">Cor/raça:</label>
            <pm-Dropdown
              v-model="info.cor"
              :options="corRacaALL"
              placeholder="Selecione a cor/raça"
            />
          </div>

          <div class="field col-12 md:col-2">
            <label for="firstname">Religião:</label>
            <pm-InputText
              id="religiao"
              @blur="mudarPrimeiraPalavra(info.religiao, 8)"
              v-model="info.religiao"
            />
          </div>
          <div class="row field col-12 md:col-3">
            <div class="field-radiobutton" style="margin-top: 24px">
              <label>Frequentará aulas de ensino religioso?</label>&nbsp;
              <pm-RadioButton
                name="sim"
                value="1"
                v-model="info.aulas_religiao_frequentara"
              />
              <label for="solteiro">Sim</label>
              <pm-RadioButton
                name="nao"
                value="0"
                v-model="info.aulas_religiao_frequentara"
                style="margin-left: 7px"
              />
              <label for="casado">Não</label>
            </div>
          </div>

          <div class="field col-12 md:col-12"></div>

          <div class="field col-12 md:col-2">
            <label for="firstname">Documento de Identidade:</label>
            <pm-InputText id="identidade" v-model="info.rg" />
          </div>
          <div class="field col-12 md:col-2">
            <label for="date">Orgão emissor:</label>
            <pm-InputText
              id="emissor"
              type="text"
              v-model="info.orgao_emissor"
            />
          </div>
          <div class="field col-12 md:col-1">
            <label for="sangue">UF:</label>
            <pm-Dropdown
              v-model="info.uf_rg"
              :options="states"
              placeholder="Estado"
            />
          </div>
          <div class="field col-12 md:col-2">
            <label for="date">Data de emissão:</label>
            <pm-InputText id="date" type="date" v-model="info.rgDataEmissao" />
          </div>
          <div class="row field col-12 md:col-4">
            <div class="field-radiobutton" style="margin-top: 24px">
              <label>Estado civil:</label>&nbsp;&nbsp;
              <pm-RadioButton
                name="Solteiro"
                @change="verificarCertidao"
                value="Solteiro"
                v-model="info.estadocivil"
              />
              <label for="solteiro">Solteiro</label>
              <pm-RadioButton
                name="Casado"
                @change="verificarCertidao"
                value="Casado"
                v-model="info.estadocivil"
                style="margin-left: 7px"
              />
              <label for="casado">Casado</label>
              <pm-RadioButton
                name="Viuvo"
                @change="verificarCertidao"
                value="Viuvo"
                v-model="info.estadocivil"
                style="margin-left: 7px"
              />
              <label for="viuvo">Viúvo</label>
              <pm-RadioButton
                name="Divorciado"
                @change="verificarCertidao"
                value="Divorciado"
                v-model="info.estadocivil"
                style="margin-left: 7px"
              />
              <label for="divorciado">Divorciado</label>
              <pm-RadioButton
                name="Outros"
                @change="verificarCertidao"
                value="Outros"
                v-model="info.estadocivil"
                style="margin-left: 7px"
              />
              <label for="outros">Outros</label>
            </div>
          </div>
          <div
            class="field col-12 md:col-3"
            v-if="info.estadocivil != 'Casado'"
          >
            <label for="firstname">Certidão de nascimento:</label>
            <pm-InputText id="email" v-model="info.certidaoNascimento" />
          </div>
          <div
            class="field col-12 md:col-3"
            v-if="info.estadocivil == 'Casado'"
          >
            <label for="firstname">Certidão de casamento:</label>
            <pm-InputText id="email" v-model="info.certidaoCasamento" />
          </div>

          <div class="field col-12 md:col-2">
            <label for="date">Livro:</label>
            <pm-InputText id="livro" type="text" v-model="info.livro_rg" />
          </div>
          <div class="field col-12 md:col-2">
            <label for="date">Folha:</label>
            <pm-InputText id="folha" type="text" v-model="info.folha_rg" />
          </div>
          <div class="field col-12 md:col-2">
            <label for="date">Termo:</label>
            <pm-InputText id="termo" type="text" v-model="info.termo_rg" />
          </div>
          <div class="field col-12 md:col-12"></div>
          <div class="field col-12 md:col-2">
            <label for="date">NIS:</label>
            <pm-InputText id="nis" type="text" v-on:keypress="isNumber($event)" v-model="info.nis" />
          </div>
          <div class="field col-12 md:col-2">
            <label for="date">SUS:</label>
            <pm-InputText id="sus" type="text" v-on:keypress="isNumber($event)" v-model="info.sus" />
          </div>
          <div class="field col-12 md:col-2">
            <label for="sangue">Tipo sanguíneo:</label>
            <pm-Dropdown
              v-model="info.tipo_sanguineo"
              :options="tipoSanguineoALL"
              placeholder="Selecione o tipo sanguineo"
            />
          </div>
          <div class="field col-12 md:col-12"></div>
          <div class="field col-12 md:col-3" style="padding-top: 16px">
            <label
            >Portador(a) de Necessidade especial?
              <pm-InputSwitch
                class="small-switch"
                v-model="info.possuiDeficiencia"
                style="vertical-align: middle !important"
              /></label>

            <div v-if="info.possuiDeficiencia">
              <label>Qual à Necessidade especial?</label>
              <pm-Textarea v-model="info.deficiencia" />
            </div>

            <div v-if="info.possuiDeficiencia" style="margin-top: 15px">
              <label
              >O aluno necessita de Mediação Escolar?
                <pm-InputSwitch
                  class="small-switch"
                  v-model="info.mediacao_escolar"
                  style="vertical-align: middle !important"
                />
              </label>
            </div>

            <div v-if="info.possuiDeficiencia" style="margin-top: 15px">
              <label
              >O aluno necessita de atendimento especial(NEE/PCD)?
                <pm-InputSwitch
                  class="small-switch"
                  v-model="info.nee"
                  style="vertical-align: middle !important"
                />
              </label>
            </div>
          </div>

          <div class="field col-12 md:col-3" style="padding-top: 16px">
            <label
            >Alérgico(a)?
              <pm-InputSwitch
                class="small-switch"
                v-model="info.alergico"
                style="vertical-align: middle !important"
              /></label>
            <div v-if="info.alergico">
              <label for="lastname">Tipo de alergia?</label>
              <pm-Textarea id="lastname" v-model="info.tipo_alergia" />
            </div>
          </div>
          <div class="field col-12 md:col-12"></div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="grid grid-nogutter justify-content-between">
          <pm-Button label="Voltar" @click="voltar()" icon="pi pi-angle-left" />
          <i></i>
          <pm-Button
            label="Próximo"
            @click="nextPage()"
            icon="pi pi-angle-right"
            iconPos="right"
          />
        </div>
      </template>
    </pm-Card>
  </div>
</template>

<script>
const state = [
  "AC",
  "AL",
  "AP",
  "AM",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MT",
  "MS",
  "MG",
  "PA",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RS",
  "RO",
  "RR",
  "SC",
  "SP",
  "SE",
  "TO",
];
import Util from "@/utils/rota";
import { Verificar } from "@/class/verificar.js";

export default {
  props: {
    dadosPessoa: { type: Array, default: () => [] },
    id: {},
    pg: {},
  },
  data() {
    return {
      tipoSanguineoALL: [
        "A+",
        "A-",
        "B+",
        "B-",
        "AB+",
        "AB-",
        "O+",
        "O-",
        "NÃO DECLARADA",
      ],
      corRacaALL: ["BRANCA", "PARDA", "PRETA", "INDÍGENA", "NÃO DECLARADA"],
      states: state,
      greenColor: "",
      cpfInvalidoIncorreto: "",
      cpfInvalidoJaExiste: "",
      info: {
        nome: "",
        sobrenome: "",
        nomeMae: "",
        nomePai: "",
        possuiDeficiencia: false,
        alergico: false,
        deficiencia: "",
        nee: false,
        mediacao_escolar: false,
        observacao: "",
        email: "",
        senha: "",
        estadocivil: "Solteiro",
        certidaoCasamento: "",
        certidaoNascimento: "",
        rg: "",
        rgDataEmissao: "",
        cpf: "",
        dataNascimento: "",
        sexo: "",
        cep: "",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        cidade: "",
        estado: "",
        telefone: [],
        sexo: "M",
        foto: "",
        cor: "NÃO DECLARADA",
        usoDaImage: false,
        responsave: "",
        numeroResponsavel: "",
        tipo_sanguineo: "",
        religiao: "",
        aulas_religiao_frequentara: true,
        emailPadrao: "",
        nacionalidade: "Brasileiro",
        escola_id:0,
      },
      emailInvalidoJaExiste: false,
      submitted: false,
      validationErrors: {},
      funcao: 0,
      usuario_logado:[],
      cad:0
    };
  },
  methods: {
    isNumber(e) {
      const char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0123456789]+$/.test(char)) {return true;}
      // Match with regex
      else {e.preventDefault();} // If not match, don't add to input text
    },
    voltar() {
      if (this.funcao === 1 || this.funcao === 2 || this.funcao === 7) {
        this.$router.push({ name: "alunosAll", params: { pg: this.pg } });
      } else {
        this.$router.push({
          name: "coordenacao-listaalunos",
          params: { pg: this.pg },
        });
      }
    },
    isLetter(e) {
      const char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ.()-- ]+$/.test(char))
        {return true;}
      // Match with regex
      else {e.preventDefault();} // If not match, don't add to input text
    },

    async verificarCPF() {
      if (this.info.cpf != null && this.info.cpf != "") {
        const data = await Verificar.verificarJaExisteCPF(this.info.cpf);
        const data1 = await Verificar.verificarCPF(this.info.cpf);

        if (this.info.id == undefined) {
          if (data.data) {
            this.cpfInvalidoJaExiste = true;
            this.cpfInvalidoIncorreto = false;
            this.greenColor = "border-color: #f44336;";
            return false;
          } else {
            this.cpfInvalidoJaExiste = false;
          }
          ////////////////////////////////////////////////////////
          if (data1.data) {
            this.cpfInvalidoIncorreto = false;
          } else {
            this.greenColor = "border-color: #f44336;";
            this.cpfInvalidoIncorreto = true;
          }

          if (!this.cpfInvalidoJaExiste && !this.cpfInvalidoIncorreto) {
            this.greenColor = "border-color:#42d36f";
          }
        } else if (
          this.info.id != undefined &&
          this.info.cpf !== this.info.cpfDele
        ) {
          if (data.data) {
            this.cpfInvalidoJaExiste = true;
            this.cpfInvalidoIncorreto = false;
            this.greenColor = "border-color: #f44336;";
            return false;
          } else {
            this.cpfInvalidoJaExiste = false;
          }
          //////////////////////////////////////////////////////////////////
          if (data1.data) {
            this.cpfInvalidoIncorreto = false;
          } else {
            this.greenColor = "border-color: #f44336;";
            this.cpfInvalidoIncorreto = true;
          }

          if (!this.cpfInvalidoJaExiste && !this.cpfInvalidoIncorreto) {
            this.greenColor = "border-color:#42d36f";
          }
        } else {
          this.cpfInvalidoJaExiste = false;
          this.cpfInvalidoIncorreto = false;
          this.greenColor = "";
        }
      } else {
        this.cpfInvalidoJaExiste = false;
        this.cpfInvalidoIncorreto = false;
        this.greenColor = "";
      }
    },
    async nextPage() {
      this.submitted = true;
      if (this.validateForm()) {
        if (!this.cpfInvalidoJaExiste && !this.cpfInvalidoIncorreto)
          {this.$emit("nextPage", { info: this.info });}
      }
    },
    validateForm() {
      if (!this.info.cpf.trim())
                {this.validationErrors['cpf'] = true;}
            else
                {delete this.validationErrors['cpf'];}   
               
      if (!this.info.nome.trim()) {this.validationErrors["nome"] = true;}
      else {delete this.validationErrors["nome"];}

      if (!this.info.nomeMae.trim()) {this.validationErrors["nomeMae"] = true;}
      else {delete this.validationErrors["nomeMae"];}

      if (!this.info.sobrenome.trim())
        {this.validationErrors["sobrenome"] = true;}
      else {delete this.validationErrors["sobrenome"];}

      return !Object.keys(this.validationErrors).length;
    },
    verificarCertidao() {
      if (this.estadocivil == "Solteiro") {
        this.certidaoCasamento = "";
        this.livro = "";
        this.folha = "";
        this.termo = "";
      } else {
        this.certidaoNascimento = "";
        this.livro = "";
        this.folha = "";
        this.termo = "";
      }
    },
    //Fazer a primeira letra maiúscula
    mudarPrimeiraPalavra(palavra, qual) {
      if (palavra) {
        let words = palavra.split(" ");
        for (let i = 0; i < words.length; i++) {
          words[i] = words[i][0].toUpperCase() + words[i].slice(1);
        }

        //juntar as palavras com um espaco
        words = words.join(" ");

        if (qual == 1) {this.info.nome = words;}
        else if (qual == 2) {this.info.sobrenome = words;}
        else if (qual == 3) {this.info.responsavel = words;}
        else if (qual == 4) {this.info.nomeMae = words;}
        else if (qual == 5) {this.info.nomePai = words;}
        else if (qual == 6) {this.info.nacionalidade = words;}
        else if (qual == 7) {this.info.naturalidade = words;}
        else if (qual == 8) {this.info.religiao = words;}
      }
    },
  },
  async beforeMount() {
    this.funcao = Number(sessionStorage.getItem("funcaoDoUsuario"));

    this.info.nome =
      this.dadosPessoa.nome != undefined
        ? this.dadosPessoa.nome
        : this.info.nome;
    this.info.sobrenome =
      this.dadosPessoa.sobrenome != undefined
        ? this.dadosPessoa.sobrenome
        : this.info.sobrenome;
    this.info.nomeMae =
      this.dadosPessoa.nomeMae != undefined
        ? this.dadosPessoa.nomeMae
        : this.info.nomeMae;
    this.info.nomePai = this.dadosPessoa.nomePai;
    this.info.possuiDeficiencia = this.dadosPessoa.possuiDeficiencia;
    this.info.deficiencia = this.dadosPessoa.deficiencia;
    this.info.observacao = this.dadosPessoa.observacao;
    this.info.nee = this.dadosPessoa.nee;
    this.info.mediacao_escolar = this.dadosPessoa.mediacao_escolar
      ? this.dadosPessoa.mediacao_escolar
      : false;

    /*if (this.dadosPessoa.emailPadrao == undefined)
            this.info.emailPadrao =  (this.dadosPessoa.email == undefined ? '' : this.dadosPessoa.email);
        else
            this.info.emailPadrao = this.dadosPessoa.emailPadrao;

        const found =  this.info.emailPadrao.match("@naotem.email");
        if (found == null)
            this.info.email =  this.dadosPessoa.email;
        else
            this.info.email =  '';
        */

    this.info.emailDele = this.dadosPessoa.emailDele;
    if (this.info.emailDele != null) {
      const found = this.info.emailDele.match("@naotem.email");
      if (found == null) {this.info.email = this.dadosPessoa.email;}
      else {this.info.email = "";}
    } else {
      this.info.email = "";
    }

    this.info.senha = this.dadosPessoa.senha;
    this.info.confirmarSenha = this.dadosPessoa.confirmarSenha;
    this.info.estadocivil =
      this.dadosPessoa.estadocivil != undefined
        ? this.dadosPessoa.estadocivil
        : this.info.estadocivil;
    this.info.certidaoNascimento = this.dadosPessoa.certidaoNascimento;
    this.info.certidaoCasamento = this.dadosPessoa.certidaoCasamento;
    this.info.rg = this.dadosPessoa.rg;
    this.info.rgDataEmissao =
      this.dadosPessoa.rgDataEmissao != undefined
        ? this.dadosPessoa.rgDataEmissao
        : this.info.rgDataEmissao;
    this.info.cpf =
      this.dadosPessoa.cpf != undefined ? this.dadosPessoa.cpf : this.info.cpf;
    this.info.cpfDele = this.dadosPessoa.cpfDele;
    this.info.dataNascimento =
      this.dadosPessoa.dataNascimento != undefined
        ? this.dadosPessoa.dataNascimento
        : this.info.dataNascimento;
    this.info.cep = this.dadosPessoa.cep;
    this.info.estado =
      this.dadosPessoa.estado != undefined ? this.info.estado : "RJ";
    this.info.cidade = this.dadosPessoa.cidade;
    this.info.bairro = this.dadosPessoa.bairro;
    this.info.logradouro = this.dadosPessoa.logradouro;
    this.info.numero = this.dadosPessoa.numero;
    this.info.complemento = this.dadosPessoa.complemento;
    this.info.telefone =
      this.dadosPessoa.telefone == undefined ? [] : this.dadosPessoa.telefone;
    this.info.sexo =
      this.dadosPessoa.sexo != undefined
        ? this.dadosPessoa.sexo
        : this.info.sexo;
    this.info.foto = this.dadosPessoa.foto;
    this.info.id =
      this.dadosPessoa.id != undefined ? this.dadosPessoa.id : this.info.id;
    this.info.login_id =
      this.dadosPessoa.login_id != undefined
        ? this.dadosPessoa.login_id
        : this.info.login_id;
    this.dadosPessoa.jaBuscou = true;
    this.info.matricula = this.dadosPessoa.matricula;
    this.info.alergico =
      this.dadosPessoa.alergico != undefined
        ? this.dadosPessoa.alergico
        : this.info.alergico;
    this.info.tipo_alergia =
      this.dadosPessoa.tipo_alergia != undefined
        ? this.dadosPessoa.tipo_alergia
        : this.info.tipo_alergia;
    this.info.nacionalidade =
      this.dadosPessoa.nacionalidade != undefined
        ? this.dadosPessoa.nacionalidade
        : this.info.nacionalidade;
    this.info.naturalidade =
      this.dadosPessoa.naturalidade != undefined
        ? this.dadosPessoa.naturalidade
        : this.info.naturalidade;
    this.info.cor =
      this.dadosPessoa.cor != undefined ? this.dadosPessoa.cor : this.info.cor;
    this.info.religiao =
      this.dadosPessoa.religiao != undefined
        ? this.dadosPessoa.religiao
        : this.info.religiao;
    this.info.aulas_religiao_frequentara =
      this.dadosPessoa.aulas_religiao_frequentara != undefined
        ? this.dadosPessoa.aulas_religiao_frequentara
        : "1";
    this.info.usoDaImage =
      this.dadosPessoa.usoDaImage != undefined
        ? this.dadosPessoa.usoDaImage
        : "0";
    this.info.responsavel =
      this.dadosPessoa.responsavel != undefined
        ? this.dadosPessoa.responsavel
        : "";
    this.info.orgao_emissor =
      this.dadosPessoa.orgao_emissor != undefined
        ? this.dadosPessoa.orgao_emissor
        : "";
    this.info.uf_rg =
      this.dadosPessoa.uf_rg != undefined ? this.dadosPessoa.uf_rg : "";
    this.info.livro_rg =
      this.dadosPessoa.livro_rg != undefined ? this.dadosPessoa.livro_rg : "";
    this.info.folha_rg =
      this.dadosPessoa.folha_rg != undefined ? this.dadosPessoa.folha_rg : "";
    this.info.termo_rg =
      this.dadosPessoa.termo_rg != undefined ? this.dadosPessoa.termo_rg : "";

    this.info.nis =
      this.dadosPessoa.nis != undefined ? this.dadosPessoa.nis : "";
    this.info.sus =
      this.dadosPessoa.sus != undefined ? this.dadosPessoa.sus : "";

    this.info.conectividade =
      this.dadosPessoa.conectividade != undefined
        ? this.dadosPessoa.conectividade
        : "1";
    this.info.tipos_dispotivos =
      this.dadosPessoa.tipos_dispotivos != undefined
        ? this.dadosPessoa.tipos_dispotivos
        : [];
    this.info.tipo_sanguineo =
      this.dadosPessoa.tipo_sanguineo != undefined
        ? this.dadosPessoa.tipo_sanguineo
        : "";

    this.info.utiliza_transporte =
      this.dadosPessoa.transporte != undefined
        ? this.dadosPessoa.transporte
        : "1";
    this.info.qual_transporte =
      this.dadosPessoa.qual_transporte != undefined
        ? this.dadosPessoa.qual_transporte
        : "";
    this.info.estadocivil =
      this.dadosPessoa.estadocivil != undefined
        ? this.dadosPessoa.estadocivil
        : "Solteiro";
    this.info.escola_id = this.dadosPessoa.escola_id ? this.dadosPessoa.escola_id : 0;
    
    const token = sessionStorage.getItem("token");
    const util = new Util();
    const data = await util.WhoIam(token);
    this.usuario_logado = data;

    this.cad = sessionStorage.getItem("cad");
    if(this.cad == "0"){
      this.info.escola_id = 9999;
    }
  },
};
</script>

<style scoped>
.p-inputswitch .p-inputswitch-slider:before {
  background: #892929;
  width: 14px;
  height: 14px;
  left: 0.25rem;
  margin-top: -0.625rem;
  border-radius: 50%;
  transition-duration: 0.2s;
}
</style>
